import "./Title.scss"

const Title = (props) => {

	return (
		<div>
			<h2>{props.title}</h2>
		</div>
	)
}

export default Title;