import Intro from "./Intro";
import News from "./News";
import Title from "./subcomponents/Title";
import Publications from "./Publications";


const Main = (props) => {

	return (
		<div>
			<Intro/>
			<Title title="News"/>
			<News/>
			<Title title="Selected Publications"/>
			<Publications property={"selected"}/>
		</div>
	)
}

export default Main;