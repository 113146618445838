import Publications from "./Publications";
import Title from "./subcomponents/Title";

const PublicationPage = (props) => {

	return (
		<div>
			<Title title="Preprints"/>
			<Publications property={"preprint"}/>
			<Title title="International Journals"/>
			<Publications property={"journal"}/>
			<Title title="Peer-reviewed Conferences"/>
			<Publications property={"conference"}/>
			<Title title="Posters, Workshops, and Domestics"/>
			<Publications property={"others"}/>
		</div>
	)
};

export default PublicationPage;

